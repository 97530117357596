import {
  AfterViewInit,
  Component,
  EventEmitter,
  inject,
  Output,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DocumentEditorComponent } from '../document-editor.component';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { CustomToolbarItemModel } from '@syncfusion/ej2-angular-pdfviewer';
import { ToolbarItem } from '@syncfusion/ej2-angular-documenteditor';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'tremaze-document-editor-dialog',
  standalone: true,
  imports: [
    CommonModule,
    DocumentEditorComponent,
    MatSidenavModule,
    MatTableModule,
    MatButtonModule,
    MatDialogModule,
  ],
  template: `
    <div style="display: flex; flex-direction: column; height: 100%">
      <div
        style="display: flex; flex-direction: row; height:calc(100% - 52px);"
      >
        <mat-drawer-container>
          <mat-drawer-content>
            <tremaze-document-editor
              [documentUrl]="url"
              [documentName]="name"
              [class]="fillable ? 'fillable-open' : 'fillable-closed'"
              style=" width: 100%; display:block "
              [additionalToolbarItems]="toolbarItems"
              (additionalToolbarItemClicked)="onToolbarClick($event)"
              #documentEditor
            >
            </tremaze-document-editor>
          </mat-drawer-content>
          @if (fillable) {
            <mat-drawer
              #sidenav
              position="end"
              mode="side"
              opened="false"
              style="width: 400px"
            >
              <div
                style="background: #fafafa; width: 400px;border: 0.5px solid #e0e0e0; padding:8px; overflow: hidden; display: flex; flex-direction: column; justify-content: space-between; max-height:100%; height: 100%;box-sizing: border-box"
              >
                <h4>Verfügbare Platzhalter</h4>
                <div *ngIf="(datasource.data.length ?? 0) === 0">
                  Keine Formularfelder vorhanden
                </div>

                <div style="flex-grow: 1; overflow-y:auto">
                  <mat-table
                    *ngIf="(datasource.data.length ?? 0) > 0"
                    [dataSource]="datasource"
                  >
                    <ng-container matColumnDef="displayName">
                      <mat-header-cell *matHeaderCellDef>
                        Platzhaltername
                      </mat-header-cell>
                      <mat-cell
                        style="word-break: break-word"
                        *matCellDef="let element"
                      >
                        {{ element.displayName }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="name">
                      <mat-header-cell *matHeaderCellDef>
                        Variable
                      </mat-header-cell>
                      <mat-cell
                        style="word-break: break-word"
                        *matCellDef="let element"
                      >
                        {{ element.name }}
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="value">
                      <mat-header-cell *matHeaderCellDef> Wert</mat-header-cell>
                      <mat-cell
                        style="word-break: break-word"
                        *matCellDef="let element"
                      >
                        {{ element.value }}
                      </mat-cell>
                    </ng-container>

                    <mat-header-row
                      *matHeaderRowDef="displayedColumns"
                    ></mat-header-row>
                    <mat-row
                      *matRowDef="let row; columns: displayedColumns"
                    ></mat-row>
                  </mat-table>
                </div>

                <div
                  *ngIf="(datasource.data.length ?? 0) > 0"
                  style="display: flex; justify-content: center;"
                >
                  <button
                    color="primary"
                    mat-button
                    (click)="replaceVariables()"
                  >
                    Platzhalter ausfüllen
                  </button>
                </div>
              </div>
            </mat-drawer>
          }
        </mat-drawer-container>
      </div>

      <div style="flex-grow: 1"></div>

      <div style="height: 52px; flex-shrink: 0" mat-dialog-actions align="end">
        <button mat-button (click)="documentEditor.print()">Drucken</button>
        <button mat-button (click)="onClickedSave()">Speichern</button>
        <button mat-button mat-dialog-close>Schließen</button>
      </div>
    </div>
  `,
  styles: [],
})
export class DocumentEditorDialogComponent implements AfterViewInit {
  readonly url = inject(MAT_DIALOG_DATA).documentUrl;
  readonly name = inject(MAT_DIALOG_DATA).documentName;
  readonly fillable: boolean = inject(MAT_DIALOG_DATA).fillable;
  readonly fillableVariables: FillableVariable[] =
    inject(MAT_DIALOG_DATA).fillableVariables;

  datasource = new MatTableDataSource<FillableVariable>(this.fillableVariables);
  displayedColumns = ['displayName', 'name', 'value'];

  @ViewChild(DocumentEditorComponent, { static: true })
  documentEditor?: DocumentEditorComponent;

  @ViewChild('sidenav') sidenav: MatDrawer | undefined;

  openFillableItem = {
    prefixIcon: 'e-form-field',
    tooltipText: 'Platzhalter',
    text: 'Platzhalter',
    id: 'openFillableItem',
  };
  toolbarItems: (CustomToolbarItemModel | ToolbarItem)[] = [
    this.openFillableItem,
    'New',
    'Open',
    'Separator',
    'Undo',
    'Redo',
    'Separator',
    'Image',
    'Table',
    'Hyperlink',
    'Bookmark',
    'TableOfContents',
    'Separator',
    'Header',
    'Footer',
    'PageSetup',
    'PageNumber',
    'Break',
    'Separator',
    'Find',
    'Separator',
    'Comments',
    'TrackChanges',
    'Separator',
    'LocalClipboard',
    'RestrictEditing',
    'Separator',
    'FormFields',
    'UpdateFields',
  ];

  onToolbarClick(id: string) {
    if (id === 'openFillableItem') {
      console.log('openFillableItem toggle sidebar');
      this.sidenav?.toggle();
    }
  }

  @Output() readonly save = new EventEmitter<Blob>();

  async onClickedSave() {
    const blob = await this.documentEditor?.save();
    if (blob) {
      this.save.emit(blob);
    }
  }

  async replaceVariables() {
    const _docEditor = this.documentEditor?.documentEditor?.documentEditor;
    for (const variable of this.fillableVariables) {
      _docEditor?.search.findAll(variable.name, 'CaseSensitive');
      _docEditor?.search.searchResults.replaceAll(
        !variable.value || variable.value.length == 0 ? '-' : variable.value,
      );
    }
  }

  ngAfterViewInit() {
    console.log(
      'DocumentEditorDialogComponent.ngAfterViewInit',
      this.datasource.data,
    );
  }
}

export class FillableVariable {
  public value: string | undefined;

  constructor(
    public readonly displayName: string,
    public readonly name: string,
    value: string | undefined,
  ) {
    this.value = value ?? '-';
  }
}
